<template>
  <cz-dialog
    v-bind="$attrs"
    v-on="$listeners"
    :title="$t('admin.workflowManagement.workflowStepDialog.title')"
  >
    <validation-observer ref="observer" v-slot="{ invalid }" tag="div">
      <v-form class="mt-2" @submit.prevent="onSubmit">
        <cz-form-field
          :label="$t('admin.workflowManagement.workflowStepDialog.stepName')"
          required
        >
          <validation-provider
            rules="required"
            :name="$t('admin.workflowManagement.workflowStepDialog.stepName')"
            v-slot="{ errors }"
          >
            <cz-input v-model="form.stepTitle" :error-messages="errors" />
          </validation-provider>
        </cz-form-field>

        <cz-form-field
          :label="
            $t('admin.workflowManagement.workflowStepDialog.approverRole')
          "
          required
        >
          <validation-provider
            rules="required"
            :name="
              $t('admin.workflowManagement.workflowStepDialog.approverRole')
            "
            v-slot="{ errors }"
          >
            <cz-autocomplete
              v-model="form.approverRole"
              :error-messages="errors"
              :items="approversRoleTypes"
              :placeholder="$t('common.selectValue')"
            />
          </validation-provider>
        </cz-form-field>
        <cz-form-field
          :label="$t('admin.workflowManagement.workflowStepDialog.stepNumber')"
          required
        >
          <validation-provider
            rules="required|min_value:1"
            :name="$t('admin.workflowManagement.workflowStepDialog.stepNumber')"
            v-slot="{ errors }"
          >
            <cz-input
              v-model="form.stepNumber"
              :error-messages="errors"
              type="number"
              readonly
            />
          </validation-provider>
        </cz-form-field>
        <cz-button
          type="submit"
          color="primary"
          :title="
            $t(
              'admin.workflowManagement.workflowStepDialog.saveStepButtonTitle'
            )
          "
          :disabled="invalid"
          :loading="loading"
        />
      </v-form>
    </validation-observer>
  </cz-dialog>
</template>

<script>
import {
  CzDialog,
  CzFormField,
  CzInput,
  CzButton,
  CzAutocomplete
} from '@/components';
import TypesMixin from '@/shared/mixins/types.mixin';
export default {
  name: 'ManageWorkflowStepDialog',
  props: {
    loading: {
      type: Boolean,
      default: false
    },
    defaultStepNumber: {
      type: Number
    }
  },
  components: {
    CzDialog,
    CzFormField,
    CzInput,
    CzButton,
    CzAutocomplete
  },
  mixins: [TypesMixin],
  created() {
    if (this.defaultStepNumber) {
      this.form.stepNumber = this.defaultStepNumber;
    }
  },
  data() {
    return {
      form: {
        stepTitle: '',
        stepNumber: undefined,
        approverRole: ''
      }
    };
  },
  methods: {
    onSubmit() {
      this.$emit('on-submit', this.form);
    }
  }
};
</script>

<style lang="scss" scoped></style>
